export default function (fileBlob: Blob, documentName: string) {
  // Create a link element to trigger the download
  const downloadUrl = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', documentName); // Set the file name here
  document.body.appendChild(link);
  link.click();

  // Clean up and revoke the URL object
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
}
